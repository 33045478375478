import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import {
  Container,
  Grid,
  AppBar,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  getParticularCourse,
  getParticularVideoOrInfo,
  auditCourseInfo,
  getVideosAndDocuments,
} from "../../redux/actions/CoursesActions";
import { getVideoProgress } from "../../redux/actions/AccountsActions";
import isEmpty from "../../reusableComponents/is-empty";
import ButtonField from "../../reusableComponents/ButtonField";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { LabelOffRounded } from "@material-ui/icons";
import { RiVideoAddLine, RiFile3Fill, RiCheckboxFill } from "react-icons/ri";
import FileView from "./FileViews";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import { postAccessLog, setCurrentUser } from "../../redux/actions/authActions";
import DialogModal from "../../reusableComponents/Modal/DialogModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "30px",
    border: "10px solid #003399",
  },
  cardContent: {
    padding: theme.spacing(2, 1),
  },
  title: {
    marginRight: theme.spacing(40),
    //paddingRight:"10px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    color: "#003399",
    fontWeight: 400,
    fontSize: 16,
    textDecoration: "none",
  },
  link: {
    fontWeight: 600,
    color: "#0F7C90",
    display: "flex",
    width: "100%",
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  linkStyle: {
    background: "#0F7C90",
    fontWeight: 600,
  },
  courseDisplay: {
    display: "flex",
    //border: "1px solid rgba(0, 0, 0, .125)" ,
    padding: "8px",
    //alignItems: "center",
    //margin: theme.spacing(1, 0),
  },
  courseTitleGreen: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#007791",
    fontWeight: 600,
    "&:hover": {
      color: "#006b82",
    },
  },
  courseTitleRed: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "red",
    fontWeight: 600,
    "&:hover": {
      color: "red",
      cursor: "default",
    },
  },
  courseTitleActive: {
    color: "#002c36",
  },
  courseTitle: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#003399",
    fontWeight: 600,
    "&:hover": {
      color: "#003399",
    },
  },
  dot: {
    height: "10px",
    width: "10px",
    backgroundColor: "#003399",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "20px",
    marginRight: "20px",
  },
  videoinfo: {
    color: "blue",
  },
  head: {
    color: "#003399",
  },

  titles: {
    // marginLeft: "100px",
  },
  lengths: {
    display: "flex",
    alignitems: "right",
    // marginLeft:"200px",
    // marginTop:"10px"
  },
  card: {
    border: "3px solid rgba(0, 0, 0, .125)",
    padding: "10px",
    backgroundColor: "#5e72e4",
    color: "white",
  },

  container: {
    border: "3px solid rgba(0, 0, 0, .125)",
    width: "100%",
  },

  reactPlayerMobile: {
    height: "30%",
    border: "1px solid rgba(0, 0, 0, .125)",
  },
  titleMobile: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    color: "blue",
    fontWeight: 400,
    fontSize: 20,
    textDecoration: "none",
  },
  selectedTitleGrid: {
    backgroundColor: "#5a87e2",
    display: "flex",
    padding: "8px",
    color: "white",
  },

  scrollable: {
    backgroundColor: "#f1f1f1",
    height: "470px",
    width: "400px",
    overflowY: "scroll",
  },

  list: {
    color: "black",
  },

  //  alignment:{
  //   //direction:"column"
  //   //marginRight:"40px"
  //  }
}));

const VideoView = ({
  getParticularCourse,
  getParticularVideoOrInfo,
  courses,
  match,
  history,
  auth,
  auditCourseInfo,
  getVideosAndDocuments,
  setCurrentUser,
  getVideoProgress,
  postAccessLog,
  accounts,
}) => {
  const { isAuthenticated, user } = auth;
  const { userCourseVideoProgress } = accounts;
  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");
  const [openPopup, setOpenPopup] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const { particularCourse, particularVideoInfo, courseVideosAndDocuments } =
    courses;
  const { courseId, videoId } = match.params;
  const [value, setValue] = React.useState("1");
  const [file, setFile] = React.useState("");
  let tabVisibility = document.visibilityState;

  const [displayFile, setDisplayFile] = React.useState(false);
  const [timeInterval, setTimeInterval] = useState(0);
  const regex = /www.youtube-nocookie.com/g;

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "Video View",
        recordedDate: new Date(),
        courseId: courseId,
        videoId: videoId,
      };
      postAccessLog(data);
    }
  }, [timeInterval, user?._id]);

  useEffect(() => {
    getParticularCourse(courseId);

    getVideosAndDocuments(courseId);
    const data = {
      userId: user?._id,
      courseId: courseId,
    };
    window.scrollTo(0, 0);
    getVideoProgress(data);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.assignedCourses) {
        const courseAssigned = user?.assignedCourses?.find(
          (item) => item === courseId
        );
        if (!courseAssigned) {
          history.push("/courses");
        }
      } else {
        setCurrentUser();
      }
    }
  }, [auth]);

  useEffect(() => {
    if (!isEmpty(particularCourse)) {
      getParticularVideoOrInfo(videoId);
    }
  }, [particularCourse, videoId]);

  useEffect(() => {
    if (particularVideoInfo?.embedUrl.match(regex) && progress % 2 == 0) {
      const videoAuditDetails = {
        userId: user._id,
        courseId: courseId,
        videoId: videoId,
        videoDuration: duration.toString(),
        videoProgress: progress.toString(),
      };
      auditCourseInfo(videoAuditDetails);
    }
    const data = {
      userId: user?._id,
      courseId: courseId,
    };
    getVideoProgress(data);
  }, [duration, progress]);

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   }
  // }, []);

  const indexValue = (id) => {
    const videoIndex = particularCourse?.videos?.findIndex(
      (list) => list._id === id
    );
    return videoIndex;
  };

  const previous = async () => {
    const getPreviousIndex = (await indexValue(particularVideoInfo?._id)) - 1;
    const previousData = await particularCourse?.videos[getPreviousIndex];
    return (window.location.href = `/course/${courseId}/${previousData._id}`);
    // history.push(`/course/${courseId}/${previousData._id}`);
    getParticularVideoOrInfo(previousData._id);
  };

  const next = async () => {
    const getNextIndex = (await indexValue(particularVideoInfo?._id)) + 1;
    const nextData = await particularCourse?.videos[getNextIndex];
    return (window.location.href = `/course/${courseId}/${nextData._id}`);
    // history.push(`/course/${courseId}/${nextData._id}`);
    getParticularVideoOrInfo(nextData._id);
  };

  const videoContent = courseVideosAndDocuments?.map((item) => {
    if (userCourseVideoProgress?.find((c) => c.videoId === item._id)) {
      item.completed = userCourseVideoProgress?.find(
        (c) => c.videoId === item._id
      ).completed;
      return item;
    }
    item.completed = false;
    return item;
  });

  const handleRestrictedVideos = (list) => {
    if (list.prerequisiteVideos?.length > 0) {
      const viewedVideos = list.prerequisiteVideos.filter((el) => {
        return !userCourseVideoProgress.some((f) => {
          return f.videoId === el._id;
        });
      });
      if (viewedVideos.length === 0) {
        return (window.location.href = `/course/${courseId}/${list._id}`);
        // history.push(`/course/${courseId}/${list._id}`);
      } else {
        SnackBar.error(
          `You have to finish ${viewedVideos
            .map((item) => item.title)
            .toString()} videos to unlock this video `
        );
      }
    } else {
      return (window.location.href = `/course/${courseId}/${list._id}`);
      // history.push(`/course/${courseId}/${list._id}`);
    }
  };

  const topFunction = () => {
    window.scrollTo(0, 0);
  };

  // const courseVideos = () => {
  //   console.log("videoContent", videoContent);
  //   return videoContent?.map(
  //     (list, idx) =>
  //       list.visibility &&
  //       !list.document && (
  //         <div
  //           key={list._id}
  //           className={
  //             videoId === list._id
  //               ? classes.selectedTitleGrid
  //               : classes.courseDisplay
  //           }
  //         >
  //           {/* style={{ borderStyle: " 1px solid",borderRadius:" 0px 0px 0px 0px"} */}
  //           {!list.document && list.visibility ? (
  //             <>
  //               {list?.completed ? (
  //                 <RiCheckboxFill size={18} color="green" />
  //               ) : (
  //                 <RiVideoAddLine size={18} />
  //               )}
  //               {/* <RiVideoAddLine size={18} /> */}
  //               <Grid container>
  //                 <Grid item xs={6} md={6}>
  //                   <Typography
  //                     // variant="subtitle2"
  //                     component="div"
  //                     className={
  //                       list.prerequisiteVideos?.length > 0
  //                         ? // userCourseVideoProgress?.find(
  //                           //     (item) => item.videoId == list._id
  //                           //   )
  //                           list.prerequisiteVideos.filter((el) => {
  //                             return !userCourseVideoProgress.some((f) => {
  //                               return f.videoId === el._id;
  //                             });
  //                           }).length === 0
  //                           ? classes.courseTitleGreen
  //                           : classes.courseTitleRed
  //                         : classes.courseTitleGreen
  //                     }
  //                     onClick={() => handleRestrictedVideos(list)}
  //                   >
  //                     <div>
  //                       <Typography
  //                         className={classes.list}
  //                         onClick={topFunction}
  //                       >
  //                         {list.title}
  //                       </Typography>
  //                     </div>
  //                   </Typography>
  //                 </Grid>

  //                 <Grid item xs={6} md={6}>
  //                   <Typography align="right">
  //                     {new Date(1000 * (list.duration ? list.duration : 0))
  //                       .toISOString()
  //                       .substr(11, 8)
  //                       .replace("00:", "")}
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </>
  //           ) : null}
  //         </div>
  //       )
  //   );
  // };

  const courseVideos = () => {
    return videoContent?.map(
      (list, idx) =>
        list.visibility &&
        !list.document && (
          <div
            key={list._id}
            className={
              videoId === list._id
                ? classes.selectedTitleGrid
                : classes.courseDisplay
            }
          >
            {/* Render icon conditionally based on completed status */}
            <>
              {list?.completed ? (
                <RiCheckboxFill size={18} color="green" />
              ) : (
                <RiVideoAddLine size={18} />
              )}
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Typography
                    component="div"
                    className={
                      list.prerequisiteVideos?.length > 0 &&
                      list.prerequisiteVideos.filter(
                        (el) =>
                          !userCourseVideoProgress.some(
                            (f) => f.videoId === el._id
                          )
                      ).length === 0
                        ? classes.courseTitleGreen
                        : classes.courseTitleRed
                    }
                    onClick={() => handleRestrictedVideos(list)}
                  >
                    <Typography className={classes.list} onClick={topFunction}>
                      {list.title}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography align="right">
                    {new Date(1000 * (list.duration || 0))
                      .toISOString()
                      .substr(11, 8)
                      .replace("00:", "")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          </div>
        )
    );
  };

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
  };

  const handleFile = (data) => {
    setOpenPopup(true);
    setFile(data.document.link);
    setDisplayFile(true);
    window.scrollTo(0, 0);
  };

  return (
    <Container maxWidth="xl" className="courseVideoViewContainer">
      <Typography variant="h5" component="div" className={classes.title}>
        <Link to="/courses" className={classes.head}>
          My Courses
        </Link>
        <span className={classes.dot}></span>
        {particularCourse?.title}
      </Typography>
      <div className="courseInfoViewContainer">
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            {!displayFile ? (
              <Card className={classes.root}>
                <Typography
                  className={classes.titleMobile}
                  variant="h5"
                  component="div"
                >
                  {particularVideoInfo?.title}
                </Typography>
                <CardContent className={classes.cardContent}>
                  {isEmpty(
                    particularVideoInfo?.embedUrl
                  ) ? null : particularVideoInfo?.embedUrl.match(regex) ? (
                    <ReactPlayer
                      className="react-player"
                      onDuration={(duration) => setDuration(duration)}
                      onProgress={(progress) =>
                        setProgress(Math.round(progress.playedSeconds))
                      }
                      url={`${particularVideoInfo?.embedUrl}`}
                      width="100%"
                      height="100%"
                      controls
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                          },
                        },
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            modestbranding: 1,
                            autoplay: 1,
                            autohide: 0,
                            theme: "dark",
                            cc_load_policy: 1,
                            rel: 0,
                            iv_load_policy: 3,
                            mute: 0,
                            loop: 1,
                          },
                        },
                      }}
                      sandbox={
                        "allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                      }
                    />
                  ) : (
                    <div className={classes.link}>
                      <ButtonField
                        name="Link"
                        buttonStyle={classes.linkStyle}
                        onClick={() =>
                          window.open(particularVideoInfo?.embedUrl, "_blank")
                        }
                      />
                    </div>
                  )}
                  <div className="courseInfoPaginations">
                    <div className="iconContainer">
                      <FaArrowAltCircleLeft
                        className={
                          indexValue(particularVideoInfo?._id) === 0
                            ? "icons disable"
                            : "icons"
                        }
                        size={25}
                        onClick={() => previous()}
                      />
                    </div>
                    <Typography
                      variant="h5"
                      component="div"
                      className={classes.videoinfo}
                    >
                      {" "}
                    </Typography>
                    <div className="iconContainer">
                      <FaArrowAltCircleRight
                        className={
                          particularCourse?.videos?.length ===
                          indexValue(particularVideoInfo?._id) + 1
                            ? "icons disable"
                            : "icons"
                        }
                        title="Next"
                        size={25}
                        onClick={() => next()}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <FileView file={file} />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <div className={classes.card}> */}
            {/* <Grid container> */}
            {/* <Grid item xs={6} md={6}>
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.panelTitle}
                  >
                    Contents
                  </Typography>
                </Grid> */}

            {/* <Grid item xs={6} md={6}>
                  <Typography
                    variant="body1"
                    component="div"
                    className={classes.panelTitle}
                    align="right"
                  >
                    {new Date(1000 * particularCourse?.duration || 0)
                      .toISOString()
                      .substr(11, 8)}
                  </Typography>
                </Grid> */}
            {/* </Grid> */}
            {/* <Typography
                  variant="body1"
                  component="div"
                  className={classes.panelTitle}
                  >
                       Contents 
                   <span className={classes.titles}> Total Hours  </span>
                   </Typography> */}
            {/* </div> */}
            {/* <Container className={classes.container}> */}
            {/* <div className={classes.scrollable}>{courseVideos()} </div> */}
            {/* </Container>
             */}
            <TabContext value={value}>
              <AppBar position="static">
                <TabList
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={`Contents  -  ${new Date(
                      1000 * particularCourse?.duration || 0
                    )
                      .toISOString()
                      .substr(11, 8)}`}
                    value="1"
                  />
                  <Tab
                    label={`Documents(${
                      videoContent?.filter(
                        (file) => file?.documentType === "Document"
                      )?.length
                    })`}
                    value="2"
                  />
                  <Tab
                    label={`Scenarios(${
                      videoContent?.filter(
                        (file) => file?.documentType === "Scenarios"
                      )?.length
                    })`}
                    value="3"
                  />
                </TabList>
              </AppBar>

              <TabPanel value="1" className={classes.scrollable}>
                {/* <div className={classes.scrollable}> */}
                {courseVideos()}
                {/* </div> */}
              </TabPanel>

              <TabPanel value="2">
                {videoContent &&
                videoContent?.filter(
                  (file) => file?.documentType === "Document"
                )?.length > 0 ? (
                  videoContent
                    ?.filter((file) => file?.documentType === "Document")
                    ?.map(
                      (list, idx) => {
                        // console.log(list.visibility);
                        return (
                          list?.visibility && (
                            <>
                              <Typography
                                // variant="subtitle2"
                                style={{ marginBottom: "5px" }}
                                component="div"
                                className={classes.courseTitleGreen}
                                onClick={() => handleFile(list)}
                              >
                                <RiFile3Fill size={18} /> {""}
                                {list?.document?.name}
                              </Typography>
                            </>
                          )
                        );
                      }
                      // (
                      //   <div key={idx} className={classes.courseDisplay}>
                      //     <Typography
                      //       variant="subtitle2"
                      //       component="div"
                      //       className={classes.courseTitle}
                      //       onClick={() => handleFile(list)}
                      //     >
                      //       {list.document.name}
                      //     </Typography>
                      //   </div>
                      // )
                    )
                ) : (
                  <Typography
                    // variant="subtitle2"
                    component="div"
                    className={classes.courseTitle}
                  >
                    No Documents found
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value="3">
                {videoContent &&
                videoContent?.filter(
                  (file) => file?.documentType === "Scenarios"
                ).length > 0 ? (
                  videoContent
                    ?.filter((file) => file?.documentType === "Scenarios")
                    ?.map((list, idx) => (
                      <>
                        <Typography
                          // variant="subtitle2"
                          component="div"
                          className={classes.courseTitleGreen}
                          onClick={() => handleFile(list)}
                        >
                          <RiFile3Fill size={18} /> {""}
                          {list.document.name}
                        </Typography>
                      </>
                    ))
                ) : (
                  <Typography
                    // variant="subtitle2"
                    component="div"
                    className={classes.courseTitle}
                  >
                    No Scenarios found
                  </Typography>
                )}
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
        <DialogModal
          title={"Document"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <div style={{ width: "1000px", height: "calc(100% - 42px)" }}>
            <FileView file={file} />
          </div>
        </DialogModal>
      </div>
    </Container>
  );
};

VideoView.propTypes = {
  getParticularCourse: PropTypes.func.isRequired,
  getParticularVideoOrInfo: PropTypes.func.isRequired,
  postAccessLog: PropTypes.func.isRequired,
  getVideoProgress: PropTypes.func.isRequired,
  auditCourseInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  getVideosAndDocuments: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
  accounts: state.accounts,
});

const mapDispatchToProps = {
  getParticularCourse,
  getParticularVideoOrInfo,
  auditCourseInfo,
  getVideosAndDocuments,
  getVideoProgress,
  postAccessLog,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoView);
