import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import {
  getAllCoursesList,
  addNewVideo,
  uploadCourseDocuments,
} from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import ReactPlayer from "react-player";
//import FileUpload from "../../../reusableComponents/FileUpload";

const useStyles = makeStyles((theme) => ({
  singleSelect: {
    margin: theme.spacing(1, 0),
  },
  prerequisite: {
    margin: theme.spacing(2, 0),
  },
  btnContailer: {
    margin: theme.spacing(2, 0),
  },
  btnSubmit: {
    float: "right",
    backgroundColor: "#003399",
    color: "white",
    opacity: "0.8",
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: "#003399",
      opacity: "1",
    },
  },
}));

const NewVideoForm = ({
  auth,
  courses,
  getAllCoursesList,
  addNewVideo,
  uploadCourseDocuments,
}) => {
  const classes = useStyles();

  const [duration, setDuration] = useState();

  const formik = useFormik({
    initialValues: {
      orderId: 0,
      title: "",
      description: "",
      embedUrl: "",
      courseDocument: "",
      courseDocumentName: "",
      relatedCourse: {},
      prerequisiteVideos: [],
    },
    validationSchema: Yup.object({
      // orderId: Yup.string().trim().required("Order Id is required field"),
      title: Yup.string()
        .trim()
        .required("Video Title is required")
        .matches(/^[A-Za-z\s]+$/, "Title should contain only alphabets"),
      embedUrl: Yup.string().trim().required("Embeded code is required"),
      description: Yup.string().trim().required("Description is required"),

      // fileUpload: Yup.string()
      //     .required('file upload is required field'),

      relatedCourse: Yup.object().required(
        "Video Related Course is required field"
      ),
    }),
    onSubmit: async (values) => {
      // console.log("add video screen (duration)",duration)
      let videoData = JSON.parse(JSON.stringify(values));
      videoData.prerequisiteVideos = values.prerequisiteVideos.map(
        (video) => video._id
      );
      videoData.orderId = values.orderId ? parseInt(values.orderId) : 0;
      // videoData.courseDocuments = {
      //   key: courseDocuments.key,
      //   link: courseDocuments.link,
      //   name: courseDocuments.name,
      // };
      videoData.duration = duration;
      // delete videoData.relatedCourse;
      // console.log("values",values);
      // console.log("videoData",videoData);

      await addNewVideo(videoData);
    },
  });

  const { isAuthenticated } = auth;
  const { allCoursesList, uploadSurveyFileProcess, courseDocuments } = courses;
  const [videoOrderList, setVideoOrderList] = useState([]);

  useEffect(() => {
    if (isEmpty(allCoursesList) && isAuthenticated) {
      getAllCoursesList();
    }
  }, [allCoursesList, isAuthenticated]);

  useEffect(() => {
    // console.log(formik.values.relatedCourse);
    if (formik?.values?.relatedCourse?.videos) {
      setVideoOrderList(formik.values?.relatedCourse?.videos);
    } else {
      setVideoOrderList([]);
    }
  }, [formik.values.relatedCourse]);

  const uploadDocuments = (e) => {
    formik.setFieldValue("courseDocument", e.target.files[0]);
    formik.setFieldValue("courseDocumentName", e.target.files[0].name);
    // e.preventDefault();

    const formData = new FormData();
    formData.append("courseDocument", e.target.files[0]);
    uploadCourseDocuments(formData);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <div className={classes.singleSelect}>
                <SelectSingleValues
                  placeholder="Related Course"
                  name="relatedCourse"
                  favourite={allCoursesList.sort((a, b) =>
                    a.title.localeCompare(b.title)
                  )}
                  onSelect={(course) =>
                    formik.setFieldValue("relatedCourse", course)
                  }
                  // selected={formik.values.relatedCourse}
                  errors={
                    formik.touched.relatedCourse && formik.errors.relatedCourse
                      ? formik.errors.relatedCourse
                      : null
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Video Title"
                type="text"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                errors={
                  formik.touched.title && formik.errors.title
                    ? formik.errors.title
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Video Description"
                type="text"
                name="description"
                multiline
                rows={7}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                errors={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : null
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Embeded code"
                type="text"
                name="embedUrl"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.embedUrl}
                errors={
                  formik.touched.embedUrl && formik.errors.embedUrl
                    ? formik.errors.embedUrl
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <ReactPlayer
                className="react-player"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                  youtube: {
                    playerVars: {
                      showinfo: 0,
                      modestbranding: 1,
                      autoplay: 1,
                      autohide: 0,
                      theme: "dark",
                      cc_load_policy: 1,
                      rel: 0,
                      iv_load_policy: 3,
                      mute: 0,
                      loop: 1,
                    },
                  },
                }}
                onDuration={(duration) => setDuration(duration)}
                url={formik?.values?.embedUrl || null}
                width="80%"
                height="100%"
                controls
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.singleSelect}>
                <SelectSingleValues
                  placeholder="Select after which video should this appear"
                  name="orderId"
                  favourite={videoOrderList || []}
                  onSelect={(video) =>
                    formik.setFieldValue("orderId", video.orderId)
                  }
                  errors={
                    formik.touched.orderId && formik.errors.orderId
                      ? formik.errors.orderId
                      : null
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <div className={classes.btnContailer}>
            <ButtonField
              type="submit"
              buttonStyle={classes.btnSubmit}
              name="Submit "
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

NewVideoForm.propTypes = {
  getAllCoursesList: PropTypes.func.isRequired,
  addNewVideo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  uploadCourseDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = {
  getAllCoursesList,
  addNewVideo,
  uploadCourseDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewVideoForm);
