import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles } from "@material-ui/core";
import {
  getAllCoursesList,
  assignNewCourse,
} from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import ButtonField from "../../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  singleSelect: {
    margin: theme.spacing(1, 0),
  },
  btnSubmit: {
    backgroundColor: "#003399",
    color: "#F8F9F9",
    opacity: "0.8",
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: "#003399",
      opacity: "1",
    },
  },
}));

const AssignCourse = ({
  userId,
  auth,
  courses,
  getAllCoursesList,
  assignNewCourse,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      assignCourse: {},
    },
    validationSchema: Yup.object({
      assignCourse: Yup.object()
        .shape({
          _id: Yup.string().trim(),
          title: Yup.string().trim(),
          createdAt: Yup.string().trim(),
          updatedAt: Yup.string().trim(),
        })
        .nullable() // Allow null value
        .required("Course is a required field to assign"),
    }),
    onSubmit: async (values) => {
      if (!isEmpty(values?.assignCourse)) {
        const newCourse = {
          courseId: values?.assignCourse?._id,
          userId,
        };

        await assignNewCourse(newCourse);
      }
    },
  });

  const { isAuthenticated } = auth;
  const { allCoursesList } = courses;

  useEffect(() => {
    if (isEmpty(allCoursesList) && isAuthenticated) {
      getAllCoursesList();
    }
  }, [allCoursesList, isAuthenticated]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <SelectSingleValues
              placeholder="Assign Course"
              name="assignCourse"
              favourite={allCoursesList}
              onSelect={(course) =>
                formik.setFieldValue("assignCourse", course || null)
              }
              // selected={formik.values.assignCourse}
              errors={
                formik.touched.assignCourse && formik.errors.assignCourse
                  ? formik.errors.assignCourse
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.singleSelect}>
            <ButtonField
              type="submit"
              buttonStyle={classes.btnSubmit}
              name="Assign Course"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

AssignCourse.propTypes = {
  getAllCoursesList: PropTypes.func.isRequired,
  assignNewCourse: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
});

const mapDispatchToProps = { getAllCoursesList, assignNewCourse };

export default connect(mapStateToProps, mapDispatchToProps)(AssignCourse);
