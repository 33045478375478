import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import FileUpload from "../../../reusableComponents/FileUpload";
import ButtonField from "../../../reusableComponents/ButtonField";
import {
  createNewCourse,
  uploadCourseImage,
} from "../../../redux/actions/CoursesActions";
import isEmpty from "../../../reusableComponents/is-empty";

const useStyles = makeStyles((theme) => ({
  btnContailer: {
    margin: theme.spacing(2, 0),
  },
  btnSubmit: {
    borderRadius: "16px",
    backgroundColor: "#003399",
    color: "#F8F9F9",
    opacity: "0.8",
    textTransform: "capitalize",
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: "#003399",
      opacity: "1",
    },
  },
}));

const NewCourseForm = ({ createNewCourse, uploadCourseImage, courses }) => {
  const classes = useStyles();
  const { courseImage, uploadSurveyFileProcess } = courses;

  const formik = useFormik({
    initialValues: {
      title: "",
      courseImage: "",
      courseImageName: "",
      description: "",

      duration: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Course Title is required field").trim(),
      courseImage: Yup.string().required("Please Upload the Image").trim(),
      description: Yup.string()
        .required("Please enter course description")
        .trim(),
      duration: Yup.string()
        .required(
          "Duration is required field! Enter 0 if course duration not available."
        )
        .matches(/^[0-9]+$/, "Must be only digits")
        .trim(),
    }),

    onSubmit: async (values) => {
      const courseData = {
        title: values.title,
        courseImage: courseImage,
        description: values.description,
        duration: parseInt(values.duration),
      };
      //   formData.append("title", values.title);
      //   formData.append("courseImage", values.courseImage);
      console.log(courseData);
      await createNewCourse(courseData);
    },
  });

  const uploadCoursePoster = (e) => {
    formik.setFieldValue("courseImage", e.target.files[0]);
    formik.setFieldValue("courseImageName", e.target.files[0].name);
    // e.preventDefault();

    const formData = new FormData();
    formData.append("courseImage", e.target.files[0]);
    uploadCourseImage(formData);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <TextFieldGroup
            label="Course Title"
            type="text"
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            errors={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : null
            }
          />
          <TextFieldGroup
            label=" Course Description"
            type="text"
            name="description"
            multiline
            rows={7}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            errors={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
          <div className={classes.btnContailer}>
            <ButtonField
              type="submit"
              buttonStyle={classes.btnSubmit}
              name="Submit"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          {uploadSurveyFileProcess ? (
            <CircularProgress />
          ) : (
            <>
              <FileUpload
                name="courseImage"
                displayName="Upload"
                fileName={
                  !isEmpty(formik.values.courseImageName)
                    ? formik.values.courseImageName
                    : "Course Image *"
                }
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(e) => uploadCoursePoster(e)}
              />
              {formik.errors.courseImage ? (
                <span>{formik.errors.courseImage}</span>
              ) : null}
            </>
          )}
          <TextFieldGroup
            label="Duration in Seconds"
            type="text"
            name="duration"
            multiline
            //rows={7}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.duration}
            errors={
              formik.touched.duration && formik.errors.duration
                ? formik.errors.duration
                : null
            }
          />
          {/* <FileUpload
            name="courseImage"
            displayName="Upload"
            fileName={
              !isEmpty(formik.values.courseImageName)
                ? formik.values.courseImageName
                : "Course Image *"
            }
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(e) => uploadCoursePoster(e)}
          /> */}
        </Grid>
      </Grid>
    </form>
  );
};

NewCourseForm.propTypes = {
  createNewCourse: PropTypes.func.isRequired,
  uploadCourseImage: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.courses,
});

const mapDispatchToProps = { createNewCourse, uploadCourseImage };

export default connect(mapStateToProps, mapDispatchToProps)(NewCourseForm);
