import React, { useState } from "react";
import { connect } from "react-redux";
//import PropTypes from 'prop-types';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, makeStyles } from "@material-ui/core";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import ButtonField from "../../reusableComponents/ButtonField";
import PageHeader from "../../reusableComponents/PageHeader";
import { PeopleOutline as PeopleOutlineIcon } from "@material-ui/icons";
import { editUser } from "../../redux/actions/authActions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  //   buttonContainer: {
  //     margin: theme.spacing(4, 0),
  //     display: "flex",
  //     justifyContent: "center",
  //   },
  formSubmit: {
    textTransform: "capitalize",
    margin: theme.spacing(4, 0),
    fontWeight: 600,
    letterSpacing: 1,
    background: "#003399",
    color: "#F8F9F9",
    "&:hover": {
      background: "#003399",
    },
  },
}));

const UserEditProfile = ({ editUser, auth }) => {
  // const [showPassword, setShowPassword] = useState(false);
  // const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const { user } = auth;
  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
      email: user.email,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Firstname field is required"),
      userName: Yup.string()
        .required("UserName field is required")
        .min(6, "UserName must be at least 6 characters"),
      email: Yup.string()
        .required("Email field is required")
        .email("Email is Invalid"),
    }),
    onSubmit: async (values) => {
      let userData = {
        userId: user?._id,
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.userName,
        email: values?.email,
      };
      await editUser(userData);
    },
  });

  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <PageHeader
        title="Users Edit Profile"
        //    subTitle="Edit Profile"
        icon={<PeopleOutlineIcon fontSize="large" />}
      />

      <Grid container spacing={2} align="center" justify="center">
        <Grid item xs={4}>
          <TextFieldGroup
            label="First Name"
            type="text"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            errors={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
          <TextFieldGroup
            label="Last Name"
            type="text"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            errors={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldGroup
            label="Username"
            type="text"
            name="userName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.userName}
            errors={
              formik.touched.userName && formik.errors.userName
                ? formik.errors.userName
                : null
            }
          />
          <TextFieldGroup
            label="Email"
            type="email"
            name="email"
            disabled
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errors={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
        </Grid>

        <Grid md={12}>
          <div item xs={12} md={12} align="center">
            <ButtonField
              buttonStyle={classes.formSubmit}
              type="submit"
              name="Submit"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

UserEditProfile.propTypes = {
  editUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { editUser };

export default connect(mapStateToProps, mapDispatchToProps)(UserEditProfile);
