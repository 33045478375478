import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Tab, Tabs } from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import {
  Container,
  Grid,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  getParticularCourse,
  getCourseDocuments,
  getVideosAndDocuments,
} from "../../redux/actions/CoursesActions";
import { getVideoProgress } from "../../redux/actions/AccountsActions";
import { RiPushpin2Fill } from "react-icons/ri";
import FileView from "./FileViews";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";

const useStyles = makeStyles((theme) => ({
  courseViewContaier: {
    margin: theme.spacing(3, 0),
  },
  title: {
    marginLeft: theme.spacing(55),
    fontWeight: 650,
    fontSize: 16,
    marginBottom: theme.spacing(1),
    color: "#003399",
  },

  panelTitle: {
    fontSize: 16,
    fontWeight: 650,
    marginRight: 400,
  },
  panel: {
    fontWeight: 650,
  },

  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  // },

  courseDisplay: {
    display: "flex",
    padding: "8px",
  },
  courseTitle: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#003399",
    fontWeight: 600,
    "&:hover": {
      color: "#003399",
    },
  },
  courseTitleGreen: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#003399",
    fontWeight: 600,
    "&:hover": {
      color: "#003399",
    },
  },
  courseTitleRed: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "red",
    fontWeight: 600,
    "&:hover": {
      color: "red",
      cursor: "default",
    },
  },
  courseCardImageContainer: {
    position: "relative",
    boxShadow: "0 5px 10px rgba(0,0,0,.05)",
    border: "1px solid #e4e4e4",
  },
  mediaImage: {
    height: 500,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "#fff",
  },
  mediaImageMobile: {
    height: 300,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "#fff",
  },
  card: {
    border: "3px solid rgba(0, 0, 0, .125)",
    padding: "10px",
    backgroundColor: "#003399",
    color: "white",
  },
  scrollable: {
    backgroundColor: "#f1f1f1",
    height: "470px",
    width: "400px",
    overflowY: "scroll",
    //margin:'15px',
    marginLeft: "7px",
    //textAlign: 'justify',
    padding: "15px",
  },
}));

const FreeCourseView = ({
  getParticularCourse,
  getCourseDocuments,
  getVideosAndDocuments,
  courses,
  match,
  history,
  accounts,
  getVideoProgress,
  auth,
}) => {
  const classes = useStyles();
  //const [expanded, setExpanded] = useState("panel1");
  const { user } = auth;
  const [value, setValue] = React.useState("1");
  const [file, setFile] = React.useState("");
  const [displayFile, setDisplayFile] = React.useState(false);
  const { userCourseVideoProgress } = accounts;

  const { particularCourse, documents, courseVideosAndDocuments } = courses;
  const { courseId } = match.params;

  useEffect(() => {
    getParticularCourse(courseId);
    getCourseDocuments(courseId);
    getVideosAndDocuments(courseId);
    getVideoProgress(courseId);
    const data = {
      userId: user?._id,
      courseId: courseId,
    };
    window.scrollTo(0, 0);
    getVideoProgress(data);
  }, []);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
  };

  const handleFile = (data) => {
    setFile(data.document.link);
    setDisplayFile(true);
    window.scrollTo(0, 0);
  };

  const topFunction = () => {
    window.scrollTo(0, 0);
  };

  const handleVideos = (list) => {
    if (list.preview) {
      return (window.location.href = `/preview/${courseId}/${list._id}`);
      // history.push(`/preview/${courseId}/${list._id}`);
    } else {
      SnackBar.error(`Video is not available for preview!!!`);
    }
  };

  const courseVideos = () => {
    return courseVideosAndDocuments?.map(
      (list, idx) =>
        list.visibility &&
        !list.document && (
          <div key={list._id} className={classes.courseDisplay}>
            <RiPushpin2Fill size={18} />
            {!list.document ? (
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className={
                      list.preview
                        ? classes.courseTitleGreen
                        : classes.courseTitleRed
                    }
                    onClick={() => {
                      handleVideos(list);
                      topFunction();
                    }}
                  >
                    {list.title}-{" "}
                    {list.preview && (
                      <span sttyle={{ color: "red", marginLeft: "120px" }}>
                        Preview Available
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography align="right">
                    {new Date(1000 * (list.duration ? list.duration : 0))
                      .toISOString()
                      .substr(11, 8)
                      .replace("00:", "")}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="subtitle2"
                component="div"
                className={classes.courseTitle}
                onClick={() => handleFile(list)}
              >
                {list.document.name}
              </Typography>
            )}
          </div>
        )
    );
  };

  return (
    <Container maxWidth="xl" className={classes.courseViewContaier}>
      <Typography variant="h6" component="div" className={classes.title}>
        {particularCourse?.title}
      </Typography>
      <Grid container spacing={5}>
        {/* <Grid
          item
          xs={12}
          md={8}
          style={{ width: "100%", height: "calc(100% - 42px)" }} 
          className="imageContentMobile"
        >
          {!displayFile ? (
            <Card className={classes.courseCardImageContainer}>
              <CardActionArea>
                <CardMedia
                  className={`${classes.mediaImageMobile}`}
                  image={particularCourse?.courseImage.link}
                  title={particularCourse?.title}
                />
              </CardActionArea>
            </Card>
          ) : (
            <FileView file={file} />
          )}
        </Grid> */}
        {/* <Grid item xs={12} md={4}>
          <div className={classes.card}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.panelTitle}
                >
                  Contents
                </Typography>
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.panel}
                  align="right"
                >
                  {new Date(1000 * particularCourse?.duration || 0)
                    .toISOString()
                    .substr(11, 8)}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div> {courseVideos()} </div>
        </Grid> */}

        <Grid
          item
          xs={12}
          md={8}
          style={{ width: "100%", height: "calc(100% - 42px)" }}
          className="image"
        >
          {!displayFile ? (
            <Card className={classes.courseCardImageContainer}>
              <CardActionArea>
                <CardMedia
                  className={`${classes.mediaImage}`}
                  image={particularCourse?.courseImage.link}
                  title={particularCourse?.title}
                />
              </CardActionArea>
            </Card>
          ) : (
            <FileView file={file} />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <div className={classes.card}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.panelTitle}
                >
                  Contents
                </Typography>
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.panel}
                  align="right"
                >
                  {particularCourse?.videos?.length > 0 &&
                    new Date(
                      particularCourse?.videos?.reduce((acc, video) => {
                        return acc + (video.duration || 0);
                      }, 0) * 1000
                    )
                      .toISOString()
                      .substr(11, 8)}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.scrollable}> {courseVideos()} </div>
        </Grid>
      </Grid>
    </Container>
  );
};

FreeCourseView.propTypes = {
  getParticularCourse: PropTypes.func.isRequired,
  getCourseDocuments: PropTypes.func.isRequired,
  getVideosAndDocuments: PropTypes.func.isRequired,
  getVideoProgress: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courses: state.courses,
  accounts: state.accounts,
});

const mapDispatchToProps = {
  getParticularCourse,
  getCourseDocuments,
  getVideosAndDocuments,
  getVideoProgress,
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeCourseView);
